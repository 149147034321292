
import { defineComponent, reactive, toRefs, computed, onBeforeMount } from "vue";
import Tabs from "@/components/atomics/Tabs.vue";
import AfterLoginPage from "@/views/layouts/AfterLoginAdminPage.vue";
import { useI18n } from "vue-i18n";
import ListItemActionDropdown from "@/components/atomics/list-item/ListItemActionDropdown.vue";
import usePopupControl from "@/lib/compositional-logic/usePopupControl";
import ListItemEditInfo from "@/components/atomics/list-item/ListItemEditInfo.vue";
import UpdateRatePopup from "@/components/popups/UpdateRatePopup.vue";
import { useRouter } from "vue-router";
import { PartnerRepository, RepositoryFactory } from "@/lib/https";
import useList from "@/lib/compositional-logic/useList";
import useNotificationControl from "@/lib/compositional-logic/useNotificationControl";

export default defineComponent({
  name: "Partner",
  components: {
    Tabs,
    AfterLoginPage,
    ListItemActionDropdown,
    ListItemEditInfo,
    UpdateRatePopup,
  },
  setup() {
    const { t } = useI18n();
    const { popupState, openPopup } = usePopupControl();
    const { openNotification } = useNotificationControl();
    const router = useRouter();

    const state = reactive({
      currentTabIndex: 0,
      rate: {
        saleRate: 0,
        developmentRate: 0,
        serviceManagementRate: 0,
        canUpdate: "",
      },
      rateToEdit: {
        saleRate: 0,
        developmentRate: 0,
        serviceManagementRate: 0,
        title: "",
        label: "",
        action: async (saleRate: any, developmentRate: any, serviceManagementRate: any) => {
          const rateJson = JSON.stringify({
            saleRate: saleRate,
            developmentRate: developmentRate,
            serviceManagementRate: serviceManagementRate,
          });
          await updatePartnerRate(rateJson);
        },
      },
    });

    const tabTags = computed(() => [
      { name: t("partner.partnerListTab") },
      { name: t("partner.partnerDistributionTab") },
    ]);

    const { getPartnerList, getPartnerRate, updatePartnerRate } =
      RepositoryFactory.getRepository<PartnerRepository>(PartnerRepository);

    const { list: partnerList, loadMore } = useList({
      initialLoadList: async ({ pageSize }) =>
        await getPartnerList({ pageSize }),
      loadMoreList: async ({ page, pageSize }) =>
        await getPartnerList({ page, pageSize }),
    });

    const partnerRate = async () => {
      const rate = await getPartnerRate();
      state.rate = rate;
    };

    onBeforeMount(partnerRate);

    const handleLoad = computed(() => loadMore);

    const actionDropdown = (
      partner: {
        id: string;
        canRead: string;
      }
    ) => [
      {
        action: () => {
          router.push({
            path: `/partner/` + partner.id,
            params: {
              partnerID: partner.id,
            },
          });
        },
        icon: "icons/details.svg",
        text: t("partner.buttonShowDetail"),
        disabled: !partner.canRead,
      },
    ];

    const clickTitle = (partner: { id: string; canRead: boolean }) => {
      if (partner.canRead === true)
        router.push({
          path: `/partner/` + partner.id,
          params: {
            partnerID: partner.id,
          },
        });
    };

    const dividendRate = computed(() => [
      {
        title: t("partner.titleSaleRate"),
        value: state.rate.saleRate + "%",
        canUpdate: state.rate.canUpdate,
        action: () => {
          state.rateToEdit.saleRate = state.rate.saleRate;
          state.rateToEdit.developmentRate = state.rate.developmentRate;
          state.rateToEdit.serviceManagementRate = state.rate.serviceManagementRate;
          state.rateToEdit.title = t("partner.editSaleRate");
          openPopup("updateRate");
        },
      },
      {
        title: t("partner.titleDevelopmentRate"),
        value: state.rate.developmentRate + "%",
      },
      {
        title: t("partner.titleServiceManagementRate"),
        value: state.rate.serviceManagementRate + "%",
      },
    ]);

    return {
      ...toRefs(state),
      tabTags,
      actionDropdown,
      dividendRate,
      popupState,
      partnerList,
      handleLoad,
      openNotification,
      partnerRate,
      clickTitle,
    };
  },
});
