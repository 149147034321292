import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ac5a100a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "popup-input-label" }
const _hoisted_2 = { class: "popup-input-label mt-2" }
const _hoisted_3 = { class: "auto-calculation" }
const _hoisted_4 = { class: "popup-input-label mt-2" }
const _hoisted_5 = { class: "auto-calculation" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ValidationTextField = _resolveComponent("ValidationTextField")!
  const _component_FlatButton = _resolveComponent("FlatButton")!
  const _component_Popup = _resolveComponent("Popup")!

  return (_openBlock(), _createBlock(_component_Popup, {
    title: _ctx.title,
    "popup-key": _ctx.popupKey
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_FlatButton, {
        onOnClick: _ctx.updateRate,
        text: _ctx.btnText,
        class: "w-full"
      }, null, 8, ["onOnClick", "text"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.$t("partner.editSaleRateLabel")), 1),
      _createVNode(_component_ValidationTextField, {
        value: _ctx.saleRateValue,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.saleRateValue) = $event)),
        valueModifiers: { number: true },
        placeholder: _ctx.placeholder,
        rules: "required|number",
        message: _ctx.errorMessage,
        type: "number",
        onChange: _ctx.rateCalculation
      }, null, 8, ["value", "placeholder", "message", "onChange"]),
      _createElementVNode("p", _hoisted_2, [
        _createTextVNode(_toDisplayString(_ctx.$t("partner.editDevelopmentRateLabel")) + " ", 1),
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t("partner.automaticCalculationGuide")), 1)
      ]),
      _createVNode(_component_ValidationTextField, {
        value: _ctx.developmentRateValue,
        "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.developmentRateValue) = $event)),
        valueModifiers: { number: true },
        readonly: true
      }, null, 8, ["value"]),
      _createElementVNode("p", _hoisted_4, [
        _createTextVNode(_toDisplayString(_ctx.$t("partner.editServiceManagementRateLabel")) + " ", 1),
        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t("partner.automaticCalculationGuide")), 1)
      ]),
      _createVNode(_component_ValidationTextField, {
        value: _ctx.serviceManagementRateValue,
        "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.serviceManagementRateValue) = $event)),
        valueModifiers: { number: true },
        readonly: true
      }, null, 8, ["value"])
    ]),
    _: 1
  }, 8, ["title", "popup-key"]))
}