
import { defineComponent, ref, PropType } from "vue";
import Popup from "@/components/popups/PopupBase.vue";
import FlatButton from "@/components/atomics/button/FlatButton.vue";
import usePopupControl, {
  PopupKey,
} from "@/lib/compositional-logic/usePopupControl";
import ValidationTextField from "@/components/atomics/ValidationTextField.vue";
import { useI18n } from "vue-i18n";

type Action = (
  saleRate: any,
  developmentRate: any,
  serviceManagementRate: any
) => void;

export default defineComponent({
  name: "UpdateRatePopup",
  components: {
    Popup,
    FlatButton,
    ValidationTextField,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    saleRate: {
      type: Number,
      default: 0,
    },
    developmentRate: {
      type: Number,
      default: 0,
    },
    serviceManagementRate: {
      type: Number,
      default: 0,
    },
    btnText: {
      type: String,
      default: "変更する",
    },
    action: {
      type: Function as PropType<Action>,
      required: true,
    },
  },
  emits: ["onUpdateRateDone"],
  setup(props, { emit }) {
    const { closePopup } = usePopupControl();
    const popupKey: PopupKey = "updateRate";
    const { t } = useI18n();

    const errorMessage: Record<string, string> = {
      error_required: t("errors.required"),
    };

    const saleRateValue = ref(props.saleRate);
    const developmentRateValue = ref(props.developmentRate);
    const serviceManagementRateValue = ref(props.serviceManagementRate);

    const rateCalculation = () => {
      if (saleRateValue.value >= 80) {
        saleRateValue.value = 79;
      } else if (saleRateValue.value <= 1) {
        saleRateValue.value = 1;
      }
      developmentRateValue.value = 80 - saleRateValue.value;
    };

    const updateRate = async () => {
      await props.action(
        saleRateValue.value,
        developmentRateValue.value,
        serviceManagementRateValue.value
      );

      emit("onUpdateRateDone");
      closePopup(popupKey);
    };

    return {
      saleRateValue,
      developmentRateValue,
      serviceManagementRateValue,
      popupKey,
      updateRate,
      errorMessage,
      rateCalculation,
    };
  },
});
