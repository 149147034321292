import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, createSlots as _createSlots, mergeProps as _mergeProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-353d0e3c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "section-header" }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "section-header big" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = ["disabled", "onClick"]
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "section-body" }
const _hoisted_9 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tabs = _resolveComponent("Tabs")!
  const _component_ListItemActionDropdown = _resolveComponent("ListItemActionDropdown")!
  const _component_ListItemEditInfo = _resolveComponent("ListItemEditInfo")!
  const _component_AfterLoginPage = _resolveComponent("AfterLoginPage")!
  const _component_UpdateRatePopup = _resolveComponent("UpdateRatePopup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_AfterLoginPage, { onScrolledToBottom: _ctx.handleLoad }, {
      "top-part": _withCtx(() => [
        _createVNode(_component_Tabs, {
          "onCurrentTab:value": _cache[0] || (_cache[0] = ($event: any) => (_ctx.currentTabIndex = $event)),
          "tab-tags": _ctx.tabTags
        }, null, 8, ["tab-tags"])
      ]),
      "left-top-part": _withCtx(() => [
        (_ctx.currentTabIndex == 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("p", null, _toDisplayString(_ctx.$t("partner.titleColumnSymbol")), 1)
              ])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t("partner.titlePartnerDistribution")), 1)
            ]))
      ]),
      "left-bottom-part": _withCtx(() => [
        (_ctx.currentTabIndex == 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.partnerList, (partner, index) => {
                return (_openBlock(), _createBlock(_component_ListItemActionDropdown, {
                  key: index,
                  actions: _ctx.actionDropdown(partner),
                  class: _normalizeClass({ 'bg-white': !partner.canRead }),
                  "has-divider": ""
                }, {
                  "title-primary": _withCtx(() => [
                    _createElementVNode("div", {
                      class: "partner-date pointer",
                      disabled: !partner.canRead,
                      onClick: ($event: any) => (_ctx.clickTitle(partner))
                    }, [
                      _createElementVNode("p", {
                        class: _normalizeClass({
                  'disabled-color': !partner.canRead,
                })
                      }, _toDisplayString(partner.name), 3)
                    ], 8, _hoisted_6)
                  ]),
                  _: 2
                }, 1032, ["actions", "class"]))
              }), 128))
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dividendRate, (rate, index) => {
                  return (_openBlock(), _createBlock(_component_ListItemEditInfo, {
                    key: index,
                    "info-title": rate.title,
                    "info-value": rate.value,
                    "disable-action": !rate.canUpdate,
                    "has-divider": "",
                    onOnEdit: rate.action
                  }, _createSlots({ _: 2 }, [
                    (!rate.action)
                      ? {
                          name: "tail",
                          fn: _withCtx(() => [
                            (!rate.action)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_9))
                              : _createCommentVNode("", true)
                          ])
                        }
                      : undefined
                  ]), 1032, ["info-title", "info-value", "disable-action", "onOnEdit"]))
                }), 128))
              ])
            ]))
      ]),
      _: 1
    }, 8, ["onScrolledToBottom"]),
    (_ctx.popupState.updateRate)
      ? (_openBlock(), _createBlock(_component_UpdateRatePopup, _mergeProps({ key: 0 }, _ctx.rateToEdit, {
          onOnUpdateRateDone: _cache[1] || (_cache[1] = 
      () => {
        _ctx.partnerRate();
        _ctx.openNotification();
      }
    )
        }), null, 16))
      : _createCommentVNode("", true)
  ], 64))
}